import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ToolIcon({ tool }) {
  return (
    <li className="list-inline-item mb-4 me-4 footerIcons">
      <img src={tool.src} alt={tool.alt} width="25rem" height="25rem" />
    </li>
  )
}

export default function Projects() {

  const allTools = [
    {
      id: 1,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg",
      alt: "MongoDB Logo"
    },
    {
      id: 2,
      src: "https://img.icons8.com/?size=512&id=kg46nzoJrmTR&format=png",
      alt: "Express Logo"
    },
    {
      id: 3,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
      alt: "React Logo"
    },
    {
      id: 4,
      src: "https://img.icons8.com/?size=512&id=hsPbhkOH4FMe&format=png",
      alt: "Node.js Logo"
    },
    {
      id: 5,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg",
      alt: "HTML Logo"
    },
    {
      id: 6,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg",
      alt: "CSS Logo"
    },
    {
      id: 7,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
      alt: "JavaScript Logo"
    },
    {
      id: 8,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/graphql/graphql-plain-wordmark.svg",
      alt: "GraphQL Logo"
    },
    {
      id: 9,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original-wordmark.svg",
      alt: "Bootstrap Logo"
    },
    {
      id: 10,
      src: "https://avatars.githubusercontent.com/u/600935?s=200&v=4",
      alt: "Mapbox Logo"
    },
    {
      id: 11,
      src: "https://reactrouter.com/_brand/react-router-mark-color.svg",
      alt: "React Router Logo"
    },
    {
      id: 12,
      src: "https://www.gstatic.com/analytics-suite/header/suite/v2/ic_analytics.svg",
      alt: "Google Analytics Logo"
    },
    {
      id: 13,
      src: "https://formspree.io/static/img/favicon.ico",
      alt: "Formspree Logo"
    },
    {
      id: 14,
      src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jquery/jquery-original-wordmark.svg",
      alt: "jQuery Logo"
    },
    {
      id: 15,
      src: "https://ik.imagekit.io/s3i7pk3bg/Project_Images/insomnia-logo.svg?updatedAt=1689443036769",
      alt: "Insomnia Logo"
    },
    // more tools here...
  ];

  // IMAGES
  const rossProject = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/ross-miller-esq.png?updatedAt=1689431499605';
  const crystalProject = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/crystal-davila-campaign.png?updatedAt=1689431528236';
  const parquesRecuerdosProject = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/parques-y-recuerdos-updated.png?updatedAt=1689431574232';
  const taskmasterProProject = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/taskmasterpro-updated.png?updatedAt=1689431664036';

  // DEPLOYED PROJECT LINKS
  const rossDeployedProjectLink = 'https://rossmiller.esq/';
  const crystalDeployedProjectLink = 'http://www.crystal-davila.com/';
  const parquesRecuerdosDeployedProjectLink = 'https://fdeaquino.github.io/parques-y-recuerdos/';
  const taskmasterProDeployedProjectLink = 'https://fdeaquino.github.io/taskmaster-pro/';

  // REPO PROJECT LINKS
  const rossRepoLink = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/ross-miller-esq.png?updatedAt=1689431499605';
  const crystalRepoLink = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/crystal-davila-campaign.png?updatedAt=1689431528236';
  const parquesRecuerdosRepoLink = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/parques-y-recuerdos-updated.png?updatedAt=1689431574232';
  const taskmasterProRepoLink = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/taskmasterpro-updated.png?updatedAt=1689431664036';


  const [shownProjectId, setShownProjectId] = useState(null);

  const projects = [
    {
      id: 1,
      name: 'Ross Miller, Esq.',
      subtitle: 'Partner & Shareholder at Gonzalez Olivieri, LLC',
      description: "A highly accessible and engaging web platform for Ross Miller, Esq., presenting his legal expertise and notable accomplishments as a renowned attorney. This project integrates advanced features like a Formspree contact form, leveraging React's capabilities to deliver a seamless user experience on any device. With its responsive design and emphasis on user-friendly interaction, the platform offers clients a smooth navigation experience. The use of Google Analytics aids in data-driven enhancements to further elevate user engagement and site performance.",
      tools: [3, 4, 7, 6, 5, 11, 12, 13],
      toolDescription: "React, Node, JavaScript, CSS, HTML, React Router, Google Analytics, Formspree",
      img: rossProject,
      visit: "Visit Website",
      deployedLink: rossDeployedProjectLink,
      repoLink: rossRepoLink,

    },
    {
      id: 2,
      name: 'Crystal Davila',
      subtitle: 'PISD School Board Member',
      description: 'A reelection website for Crystal Davila showcasing her political agenda and achievements as a School Board Member in Pasadena ISD. The website also features ways for supporters to get involved and stay informed about her campaign. Designed with responsiveness in mind, the site delivers an optimal viewing experience on any device, particularly mobile devices. Additionally, Google Analytics is utilized to gather user data, enabling continuous improvement in user engagement and site performance.',
      tools: [1, 2, 3, 4, 11, 12, 9, 7, 6, 5],
      toolDescription: "MERN Stack (MongoDB, Express, React, Node), JavaScript, CSS, HTML, Bootstrap, React Router, Google Analytics",
      img: crystalProject,
      visit: "Visit Website",
      deployedLink: crystalDeployedProjectLink,
      repoLink: crystalRepoLink,
    },
    {
      id: 3,
      name: 'Parques y Recuerdos',
      description: "Parques y Recuerdos: 'Spanglish Reflections on Park Adventures' is a vibrant platform intertwining travel, photography, and technology. Displaying my skills with REACT, Node.js, Express, and Mapbox, it tells a unique 'Spanglish' narrative of various park adventures, while capturing my journey as a bilingual individual. It offers an optimized, engaging storytelling experience on any device. Beyond showcasing my technical abilities, Parques y Recuerdos invites you to explore the rich experiences embedded within and embark on your own adventure.",
      tools: [2, 3, 4, 7, 6, 5, 9, 10, 11, 12],
      toolDescription: "Express, React, Node, JavaScript, CSS, HTML, Bootstrap, Mapbox, React Router, Google Analytics",
      img: parquesRecuerdosProject,
      visit: "Visit Website",
      deployedLink: parquesRecuerdosDeployedProjectLink,
      repoLink: parquesRecuerdosRepoLink,
    },
    {
      id: 4,
      name: 'Taskmaster Pro',
      description: "Taskmaster Pro is a task management tool that enables users to create tasks with descriptions and deadlines. The app uses localStorage to save task data and includes user-friendly features such as drag-and-drop functionality to delete one task and a delete all button to delete all tasks. This tool is also installable as a Progressive Web Applicaiton - PWA.",
      tools: [4, 7, 6, 5, 9, 14],
      toolDescription: "Node, JavaScript, CSS, HTML, Bootstrap, jQuery",
      img: taskmasterProProject,
      visit: "Visit Website",
      deployedLink: taskmasterProDeployedProjectLink,
      repoLink: taskmasterProRepoLink,
    },
    // more projects here...
  ];

  const techSolutions = [
    {
      id: 1,
      title: "Google Analytics",
      description: "Tapping into the capabilities of Google Analytics has elevated my project outcomes.  Its insights into user behaviors and engagement patterns have been invaluable. By basing my adjustments on tangible data, I've ensured that each project resonates better with its intended audience."
    },
    {
      id: 2,
      title: "Web Responsiveness",
      description: "In an era defined by device diversity, web responsiveness becomes paramount. It's not just about design—it's about inclusivity. I'm committed to ensuring every user, no matter their device of choice, enjoys an optimal experience with my projects."
    },
    {
      id: 3,
      title: "Modern Tech",
      description: "Navigating the expansive world of web tech, I've anchored my approach in the MERN Stack. The responsiveness of React, bolstered by the robustness of back-end tools, empowers me to create projects at the forefront of web innovation."
    },
    {
      id: 4,
      title: "Optimized Performance",
      description: "Speed and efficiency are non-negotiable in the web landscape. Using tools like Google Lighthouse, I continuously refine my projects, ensuring fast load times, efficient code, and top-tier performance across all platforms."
    }

  ];

  const [activeSolutionId, setActiveSolutionId] = useState(1);  // Defaulting to Google Analytics

  const handleToggle = (id) => {
    if (activeSolutionId === id) {
      setActiveSolutionId(null);  // Collapse the item if it's already expanded
    } else {
      setActiveSolutionId(id);    // Otherwise, set this item as the active one
    }
  };

  return (
    <React.Fragment>
      <>
        <section className='pt-24 pb-10 bg-light-light overflow-hidden'>
          <div className='container'>
            <div className='mw-3xl mx-auto text-center mb-20 mb-sm-10'>
              <h1 className='pb-2'>Explore My Recent Projects</h1>
            </div>
            <div className='row mb-n20'>
              {projects.map(project => (
                <div className='col-12 col-lg-6 mb-20' key={project.id}>
                  <a className='d-block' href={project.deployedLink}>
                    <img
                      className='d-block mb-8 w-100 img-fluid rounded-5'
                      src={project.img}
                      alt=''
                    />
                  </a>
                  <div>
                    <h3 className='fs-3 mb-3'>{project.name}</h3>
                    <div className='mb-6'>
                      <span className='fs-9 fw-semibold text-secondary-light'>
                        {project.subtitle}
                      </span>
                    </div>
                    <p className='mw-xl text-secondary-light'>
                      {project.description}
                    </p>
                  </div>
                  <div className="d-flex align-content-center mb-1 cursor-pointer" onClick={() => setShownProjectId(shownProjectId === project.id ? null : project.id)}>
                    <p className="mb-4 me-4 text-secondary-light text-decoration-underline">View Tools Used:</p>
                    <svg
                      style={{ transform: shownProjectId === project.id ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                      width={20}
                      height={20}
                      viewBox='0 0 26 26'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M19.4961 10.4346L12.9483 3.88682L6.40058 10.4346'
                        stroke='black'
                        strokeWidth='1.61806'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12.9453 22.2246L12.9453 4.06996'
                        stroke='black'
                        strokeWidth='1.61806'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  {shownProjectId === project.id &&
                    <div>
                      <ul>
                        {project.tools.map(id => {
                          const tool = allTools.find(tool => tool.id === id);
                          if (tool) {
                            return <ToolIcon key={tool.id} tool={tool} />;
                          }
                          return null;
                        })}
                      </ul>
                      <p className='text-secondary-light'>{project.toolDescription}</p>
                    </div>
                  }


                </div>

              ))}



              
            </div>
            <div className='mt-20 text-center'>
              <a className='btn btn-outline-dark fw-semibold' href='https://github.com/fdeaquino'>
                <span className='me-2'>View More</span>
                <svg
                  className='rotate-45'
                  width={10}
                  height={11}
                  viewBox='0 0 10 11'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9 1.5L1 9.5'
                    stroke='currentColor'
                    strokeWidth='1.3'
                    strokeMiterlimit={10}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M9 8.83571V1.5H1.66429'
                    stroke='currentColor'
                    strokeWidth='1.3'
                    strokeMiterlimit={10}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>
        <section className='py-12 py-md-20 bg-light-light'>
          <div className='container'>
            <div className='mw-xl mx-auto mw-lg-none'>
              <h2 className='image-text mb-20 text-center'>
                Enhanced Outcomes Through Modern Tech Solutions
              </h2>
              <div className='row'>
                <div className='col-12 col-lg-6 mb-12 mb-lg-0'>
                  <div className='mw-xl'>
                    {techSolutions.map(solution => (
                      <div key={solution.id} className='py-10 px-0 border-bottom'>
                        <div className='d-flex w-100 justify-content-between align-items-center' style={{
                          cursor: 'pointer'
                        }} onClick={() => handleToggle(solution.id)}>
                          <h5 className='fs-3 fw-medium mb-0'>{solution.title}</h5>
                          <button className='flex-shrink-0 ms-4 btn btn-link p-0'>
                            <svg
                              style={activeSolutionId === solution.id ? { transform: 'rotate(180deg)', transition: 'transform 0.3s ease' } : {}}
                              width={26}
                              height={26}
                              viewBox='0 0 26 26'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M19.4961 10.4346L12.9483 3.88682L6.40058 10.4346'
                                stroke='black'
                                strokeWidth='1.61806'
                                strokeMiterlimit={10}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M12.9453 22.2246L12.9453 4.06996'
                                stroke='black'
                                strokeWidth='1.61806'
                                strokeMiterlimit={10}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </button>

                        </div>
                        {activeSolutionId === solution.id && (
                          <div className='mt-8'>
                            <p className='fs-6 mb-0'>{solution.description}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-12 col-lg-6'>
                  <img
                    className='img-fluid d-block mx-auto me-lg-0'
                    src='https://ik.imagekit.io/s3i7pk3bg/Project_Images/device-sizes-brickstreet-2222_Ta5on8nYg?updatedAt=1690049290600'
                    alt='tech solutions'
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
    </React.Fragment>
  );
}

