import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';


export default function SinglePost({ blogData }) {
  const { id } = useParams();
  const navigate = useNavigate();

  // Find the case that matches the id in the URL
  const blogDetail = blogData.find(blogItem => blogItem.id === Number(id));

  const handleGoBack = (event) => {
    event.preventDefault();  // Prevent default link behavior
    navigate(-1);
  };

  // Split the description into paragraphs
  const descriptionParagraphs = blogDetail.description.split('\n\n');

  return (
    <React.Fragment>
      <section className='pt-24 pb-10 bg-light-light overflow-hidden'>
        <div className='container'>
          <div className='mw-lg mw-lg-6xl mx-auto'>
            <div id='single-post-view-mobile' className='row'>
              <div className='col-12 col-lg-6 mb-16 mb-lg-0'>
                <div className='mw-lg'>
                  <h2 className='mw-lg-lg fs-1 mb-8'>{blogDetail.title}</h2>

                  {/* Map over the paragraphs and render each one */}
                  {descriptionParagraphs.map((paragraph, index) => (
                    <p key={index} className='fs-6 mb-10'>{paragraph}</p>
                  ))}

                  <a
                    className='d-inline-block pb-2 fw-semibold border-0 border-2 border-dark border-bottom'
                    onClick={handleGoBack}
                  >
                    <span className='me-3'>All Blog Posts</span>
                    <svg
                      className='rotate-45'
                      width={10}
                      height={11}
                      viewBox='0 0 10 11'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9 1.5L1 9.5'
                        stroke='black'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9 8.83571V1.5H1.66429'
                        stroke='black'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div id='blog-img' className='col-12 col-lg-6'>
                <img
                id='blog-img-height'
                  className='img-fluid d-block ms-lg-auto rounded'
                  src={blogDetail.imgSrcSingleBlogView}
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

