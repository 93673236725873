import React, { useState } from 'react';

export default function Skillsandresume() {
  const [activeSection, setActiveSection] = useState('frontEnd');

  const gradients = [
    "linear-gradient(98.24deg, #95BDA6ff 0%, #518C80ff 50%, #6F9C8Bff 100%)",  // Cambridge Blue to Viridian to Cambridge Blue
    "linear-gradient(98.24deg, #135055ff 0%, #39786Fff 50%, #518C80ff 100%)",  // Midnight Green to Myrtle Green to Viridian
    "linear-gradient(98.24deg, #266463ff 0%, #39786Fff 50%, #648671ff 100%)",  // Caribbean Current to Myrtle Green to Viridian (alternate shade)
    "linear-gradient(98.24deg, #6F9C8Bff 0%,  #95BDA6ff 100%)",  // Cambridge Blue to Brunswick Green to Cambridge Blue
  ];



  const getRandomGradient = () => gradients[Math.floor(Math.random() * gradients.length)];

  const frontEndSkills = ["Front-End Development", "React", "Bootstrap", "Responsive Design", "JavaScript", "CSS", "HTML", "Mobile First"];

  const backEndSkills = ["Back-End Development", "APIs", "Node", "Express", "MySQL", "NoSQL", "Sequelize", "MongoDB", "Mongoose", "REST", "GraphQL", "Servers", "Databases",];

  const technicalSkills = ["Project Management", "Git Version Control", "Webpack", "Google Analytics", "Google Lighthouse", "Regex", "JSON", "REST", "Debugging", "Data Analysis", "Data Management"];

  const transferableSkills = ["Spanish Speaker", "Bilingual", "Professional Communication", "Problem Solving", "Collaboration", "Time Management", "Organization", "Cultural Awareness"];

  // Function to render the skills based on the active section
  const renderSkills = () => {
    switch (activeSection) {
      case 'frontEnd':
        return frontEndSkills.map((skill, index) => {
          const gradient = gradients[index % gradients.length];  // Get gradient based on index
          return (
            <div className="col-auto d-flex justify-content-center pb-5">
              <div className="d-inline-flex justify-content-center align-items-center p-4 bg-white" style={{ borderRadius: "40px" }}>
                <div>
                  <div className="inline-block me-3" style={{ width: "14px", height: "14px", borderRadius: "40px", background: gradient }}></div>
                </div>
                <h5 key={skill} className="fs-8 fw-semibold mb-0 text-secondary">{skill}</h5>
              </div>
            </div>
          );
        });
      case 'backEnd':
        return backEndSkills.map((skill, index) => {
          const gradient = gradients[index % gradients.length];  // Get gradient based on index
          return (
            <div className="col-auto d-flex justify-content-center pb-5">
              <div className="d-inline-flex justify-content-center align-items-center p-4 bg-white" style={{ borderRadius: "40px" }}>
                <div>
                  <div className="inline-block me-3" style={{ width: "14px", height: "14px", borderRadius: "40px", background: gradient }}></div>
                </div>
                <h5 key={skill} className="fs-8 fw-semibold mb-0 text-secondary">{skill}</h5>
              </div>
            </div>
          );
        });
      case 'transferable':
        return transferableSkills.map((skill, index) => {
          const gradient = gradients[index % gradients.length];  // Get gradient based on index
          return (
            <div className="col-auto d-flex justify-content-center pb-5">
              <div className="d-inline-flex justify-content-center align-items-center p-4 bg-white" style={{ borderRadius: "40px" }}>
                <div>
                  <div className="inline-block me-3" style={{ width: "14px", height: "14px", borderRadius: "40px", background: gradient }}></div>
                </div>
                <h5 key={skill} className="fs-8 fw-semibold mb-0 text-secondary">{skill}</h5>
              </div>
            </div>
          );
        });
      case 'technical':
        return technicalSkills.map((skill, index) => {
          const gradient = gradients[index % gradients.length];  // Get gradient based on index
          return (
            <div className="col-auto d-flex justify-content-center pb-5">
              <div className="d-inline-flex justify-content-center align-items-center p-4 bg-white" style={{ borderRadius: "40px" }}>
                <div>
                  <div className="inline-block me-3" style={{ width: "14px", height: "14px", borderRadius: "40px", background: gradient }}></div>
                </div>
                <h5 key={skill} className="fs-8 fw-semibold mb-0 text-secondary">{skill}</h5>
              </div>
            </div>
          );
        });
      default:
        return null;
    }
  };


  // const FeatureCard = ({ gradient, title }) => (
  //   <div className="col-auto d-flex justify-content-center">
  //     <div className="d-inline-flex justify-content-center align-items-center p-4 bg-white" style={{ borderRadius: "40px" }}>
  //       <div>
  //         <div className="inline-block me-3" style={{ width: "14px", height: "14px", borderRadius: "40px", background: gradient }}></div>
  //       </div>
  //       <h3 className="fs-10 fw-semibold mb-0 text-secondary">{title}</h3>
  //     </div>
  //   </div>
  // );

  return (
    <React.Fragment>

      <>
        <section className='pt-24 pb-10 bg-light-light overflow-hidden'>
          <div className='container'>
            <div className='row justify-content-center text-center align-items-center mb-6'>
              <div className='col-10  mb-6 mb-lg-0'>
                <h1 className='pb-2'>A Track Record of Adaptability and Growth</h1>
              </div>
            </div>
            <div className='row justify-content-center align-items-baseline'>
              <div className='col-12 col-lg-6 mb-6 mb-lg-0'>
                <div className='mw-xl'>
                  <p className='fw-medium mb-10'>
                    <span className='me-1'>
                      Every phase of my career, from teaching to tech, underscores a commitment to growth and adaptability. Merging skills from both worlds, especially the interpersonal strengths from teaching and the technical expertise from web development, has been invaluable. While teaching strengthened my collaborative and mentoring muscles, web development ignited my creative spark.
                    </span>
                    <span className='text-secondary-light'>
                      I hold a B.S. in Human Development & Family Sciences from The University of Texas, Austin (2016). Further expanding my technical horizon, I earned a Full-Stack Web Development Certificate from Rice University (2023), mastering technologies from HTML to Node.js.

                    </span>
                  </p>
                  <a
                    className='d-inline-block pb-2 fw-semibold border-0 border-2 border-dark border-bottom'
                    href='https://drive.google.com/file/d/1c-YuXCQqfeMpk4xi-3aIrK2XXu6N8hTQ/view?usp=sharing'
                  >
                    <span className='me-3'>View Full Resume</span>
                    <svg
                      className='rotate-45'
                      width={11}
                      height={11}
                      viewBox='0 0 11 11'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9.5 1.5L1.5 9.5'
                        stroke='black'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9.5 8.83571V1.5H2.16429'
                        stroke='black'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <ul className='list-unstyled mt-10'>
                  <li className='d-flex mb-6 pb-3 border-2 border-dark border-bottom align-items-end justify-content-between'>
                    <span className='fs-5 fw-medium text-secondary-light'>
                      Present
                    </span>
                    <div className='text-end'>
                      <span className='d-block fw-medium fs-5'>
                        <span>Full-Stack Web Developer</span>
                      </span>
                      <span className='fw-medium'>
                        Flexico Digital Studio
                      </span>
                    </div>
                  </li>
                  <li className='d-flex mb-6 pb-3 border-2 border-dark border-bottom align-items-end justify-content-between'>
                    <span className='fs-5 fw-medium text-secondary-light'>
                      '22 - '23'
                    </span>
                    <div className='text-end'>
                      <span className='d-block fw-medium fs-5'>
                        <span>Coding Bootcamp Student</span>
                      </span>
                      <span className='fw-medium'>
                        Rice University
                      </span>
                    </div>
                  </li>
                  <li className='d-flex mb-8 pb-3 border-2 border-dark border-bottom align-items-end justify-content-between'>
                    <span className='fs-5 fw-medium text-secondary-light'>
                      '21 - '22
                    </span>
                    <div className='text-end'>
                      <span className='d-block fw-medium fs-5'>
                        School Administrator
                      </span>
                      <span className='fw-medium'>Baker Ripley</span>
                    </div>
                  </li>
                  <li className='d-flex mb-8 pb-3 border-2 border-dark border-bottom align-items-end justify-content-between'>
                    <span className='fs-5 fw-medium text-secondary-light'>
                      '16 - '21
                    </span>
                    <div className='text-end'>
                      <span className='d-block fw-medium fs-5'>
                        School Teacher
                      </span>
                      <span className='fw-medium'>Baker Ripley</span>
                    </div>
                  </li>
                  <li className='d-flex pb-3 border-2 border-dark border-bottom align-items-end justify-content-between'>
                    <span className='fs-5 fw-medium text-secondary-light'>
                      '11 - '16
                    </span>
                    <div className='text-end'>
                      <span className='d-block fw-medium fs-5'>
                        <span>College Student</span>
                      </span>
                      <span className='fw-medium'>
                        The University of Texas at Austin
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="py-12 py-md-24 bg-light-light overflow-hidden">
          <div className="container">
            <div className="mw-md-lg mx-auto text-center mb-20">
              <h2 className="fs-11 mb-0 mb-5 image-text">Knowledge That Sets Me Apart</h2>
              <p className="mw-md-md mx-auto mb-0">With a unique blend of creativity and technical skills, I deliver websites that not only look good but perform optimally.</p>
            </div>
            <div className="row">
              <div className="col-12 col-lg-5 order-last order-lg-first">
                <img className="img-fluid d-block mx-auto mx-lg-0 rounded-5" src="https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" alt="" />
              </div>
              <div className="col-12 col-lg-7 mb-12 mb-lg-0">
                <button className="btn p-0 pb-6 mb-8 text-start border-0 border-bottom border-2 rounded-0 w-100" onClick={() => setActiveSection(activeSection === 'frontEnd' ? null : 'frontEnd')}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-inline-block me-8 fs-9">01</span>
                    <span className="d-inline-block me-auto fs-3 fw-normal">Front-End Proficiencies</span>
                    <svg style={{ transform: activeSection === 'frontEnd' ? 'rotate(180deg)' : 'rotate(0deg)' }}
                      width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8223 15.0312L12.4993 21.3542L6.17643 15.0313" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M12.5 3.646L12.5 21.1772" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </div>

                </button>
                <div className='container mb-8'>
                  <div className='row justify-content-center'>
                    {activeSection === 'frontEnd' && renderSkills({ gradient: getRandomGradient() })}
                  </div>

                </div>
                <button className="btn p-0 pb-6 mb-8 text-start border-0 border-bottom border-2 rounded-0 w-100" onClick={() => setActiveSection(activeSection === 'backEnd' ? null : 'backEnd')}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-inline-block me-8 fs-9">02</span>
                    <span className="d-inline-block me-auto fs-3 fw-normal">Back-End Proficiencies</span>
                    <svg style={{ transform: activeSection === 'backEnd' ? 'rotate(180deg)' : 'rotate(0deg)' }}
                      width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8223 15.0312L12.4993 21.3542L6.17643 15.0313" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M12.5 3.646L12.5 21.1772" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </div>

                </button>
                <div className='container mb-8'>
                  <div className='row justify-content-center'>
                    {activeSection === 'backEnd' && renderSkills({ gradient: getRandomGradient() })}
                  </div>

                </div>

                <button className="btn p-0 pb-6 mb-8 text-start border-0 border-bottom border-2 rounded-0 w-100" onClick={() => setActiveSection(activeSection === 'technical' ? null : 'technical')}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-inline-block me-8 fs-9">03</span>
                    <span className="d-inline-block me-auto fs-3 fw-normal">Other Technical Skills</span>
                    <svg style={{ transform: activeSection === 'technical' ? 'rotate(180deg)' : 'rotate(0deg)' }}
                      width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8223 15.0312L12.4993 21.3542L6.17643 15.0313" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M12.5 3.646L12.5 21.1772" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </div>

                </button>
                <div className='container mb-8'>
                  <div className='row justify-content-center'>
                    {activeSection === 'technical' && renderSkills({ gradient: getRandomGradient() })}
                  </div>

                </div>

                <button className="btn p-0 pb-6 mb-8 text-start border-0 border-bottom border-2 rounded-0 w-100" onClick={() => setActiveSection(activeSection === 'transferable' ? null : 'transferable')}
                >
                  <div className="d-flex align-items-center">
                    <span className="d-inline-block me-8 fs-9">04</span>
                    <span className="d-inline-block me-auto fs-3 fw-normal">Transferable Skills</span>
                    <svg style={{ transform: activeSection === 'transferable' ? 'rotate(180deg)' : 'rotate(0deg)' }}
                      width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.8223 15.0312L12.4993 21.3542L6.17643 15.0313" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M12.5 3.646L12.5 21.1772" stroke="black" strokeWidth="1.61806" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                  </div>

                </button>
                <div className='container mb-8'>
                  <div className='row justify-content-center'>
                    {activeSection === 'transferable' && renderSkills({ gradient: getRandomGradient() })}
                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>

      </>
    </React.Fragment>
  );
}

