import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('home');
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {
    const currentPath = location.pathname.split('/')[1] || 'home';
    setActiveMenuItem(currentPath);
  }, [location]);


  const handleToggle = () => {
    setIsActive(!isActive);
    setIsExpanded(!isExpanded);
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.style.filter = isExpanded ? 'none' : 'blur(10px)';
    }
  };

  const closeMenu = () => {
    setIsActive(false);
    setIsExpanded(false);
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.style.filter = 'none';
    }
  };


  const ContactLink = (
    <Link
      className='btn btn-outline-dark fw-medium d-flex align-items-center rounded-pill'
      to='/contact'
      onClick={closeMenu}
    >
      <span className='me-3'>Get In Touch</span>
      <svg
        className='rotate-45'
        width={10}
        height={11}
        viewBox='0 0 10 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9 1.5L1 9.5'
          stroke='currentColor'
          strokeWidth='1.3'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9 8.83571V1.5H1.66429'
          stroke='currentColor'
          strokeWidth='1.3'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Link>
  );

  return (
    <React.Fragment>
      <header id="cs-navigation" className={`bg-light-light ${isActive ? 'cs-active' : ''}`}>

        <nav className='cs-nav navbar-expand-lg navbar-light d-flex align-items-center'>
          <Link to="/" className="cs-logo" aria-label="back to home" onClick={closeMenu}>
            <h3 id='logo-font-size' class="mb-0 image-text">Fidel Deaquino</h3>
          </Link>
          <button
            className={`cs-toggle ${isExpanded ? 'cs-active' : ''}`}
            aria-label="mobile menu toggle"
            onClick={handleToggle}
            aria-expanded={isExpanded}
          >
            <div className="cs-box" aria-hidden="true">
              <span className="cs-line cs-line1" aria-hidden="true"></span>
              <span className="cs-line cs-line2" aria-hidden="true"></span>
              <span className="cs-line cs-line3" aria-hidden="true"></span>
            </div>
          </button>
          <div className="cs-ul-wrapper">
            <ul
              id="navbar-align-items-center cs-expanded "
              className={`cs-ul navbar-nav ms-lg-auto me-4 ${isExpanded ? 'cs-active' : ''}`}
            >
              <li className={`nav-item`}>
                <Link
                  className={`nav-link fw-medium cs-li-link ${activeMenuItem === "home" ? "cs-active active" : ""}`}
                  to='/'
                  onClick={closeMenu}
                >
                  About
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link
                  className={`nav-link fw-medium cs-li-link ${activeMenuItem === "projects" ? "cs-active active" : ""}`}
                  to='/projects'
                  onClick={closeMenu}
                >
                  Projects
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link
                  className={`nav-link fw-medium cs-li-link ${activeMenuItem === "skillsandresume" ? "cs-active active" : ""}`}
                  to='/skillsandresume'
                  onClick={closeMenu}
                >
                  Skills &amp; Resume
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link
                  className={`nav-link fw-medium cs-li-link ${activeMenuItem === "blog" ? "cs-active active" : ""}`}
                  to='/blog'
                  onClick={closeMenu}
                >
                  Blog
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link
                  className='btn btn-outline-dark fw-medium d-flex align-items-center rounded-pill'
                  to='/contact'
                  onClick={closeMenu}
                >
                  <span className='me-3'>Get In Touch</span>
                  <svg
                    className='rotate-45'
                    width={10}
                    height={11}
                    viewBox='0 0 10 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9 1.5L1 9.5'
                      stroke='currentColor'
                      strokeWidth='1.3'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9 8.83571V1.5H1.66429'
                      stroke='currentColor'
                      strokeWidth='1.3'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </Link>
              </li>
            </ul>
            {/* <div id='navbar-contact-button-center'>
              {!isExpanded ? ContactLink : null}
            </div> */}
          </div>
        </nav>
      </header>



    </React.Fragment>

  );
}
