import React from 'react';
import { Link } from 'react-router-dom';
import spinBonusImage from '../assets/asitrastudio-assets/headers/fd-spin-bonus.png';
const deskPhoto = 'https://ik.imagekit.io/s3i7pk3bg/Project_Images/desk-photo.jpg?updatedAt=1689892403804';



export default function Home() {
  return (
    <React.Fragment>
      <>
        <section className='pt-10 pt-sm-10 pt-md-12 pt-lg-24 pb-10 bg-light-light overflow-hidden'>
          <div className='container'>
            <div id='hide-banner-2-justify-content-center' className='position-relative row mb-20 mb-sm-28 mb-md-32 mb-lg-24 flex-switch'>
              <div
                id='dynamic-spinner'
                className='position-absolute  ms-n24  translate-middle-y d-flex align-items-center justify-content-center'

              >
                <img
                  className='position-absolute top-0 start-0 w-100 h-100 spin'
                  src={spinBonusImage}
                  alt=''
                />
                <img
                  className='position-relative'
                  src='asitrastudio-assets/headers/arrow-spin.svg'
                  alt=''
                />
              </div>
              <div className='col-12 col-lg-7 mb-8 mb-lg-0'>
                <div id='dynamic-banner'
                  className='img-fluid w-100 rounded-5' style={{ backgroundSize: 'cover' }}>
                </div>
              </div>
              <div id='hide-banner' className='col-5 col-lg-3 col-xl-2 mb-8 mb-lg-0'>
                <div className=' banner-triple2 img-fluid rounded-5' style={{ height: 277, backgroundSize: 'cover' }}>
                </div>
              </div>

              <div id='hide-banner' className='col-7 col-lg-2 col-xl-3 mb-8 mb-lg-0' >
                <div className=' banner-triple3 img-fluid rounded-5' style={{ height: 277, backgroundSize: 'cover' }}>
                </div>
              </div>

            </div>
            <div className='position-relative mw-5xl mx-auto text-center'>
              <h1 id='hero-font-size' className='display-3'>
                I'm Fidel &amp; I Develop Modern Websites
              </h1>
            </div>
          </div>
        </section>
        <section className='py-12 py-md-20 bg-light-light'>
          <div className='container'>
            {/* <h1 className='mb-20'>Get started quickly</h1> */}
            <div className='pb-10 pt-20 pt-lg-10 px-12 px-xl-32 bg-dark rounded-5'>
              <div className='row align-items-center'>
                <div className='col-12 col-lg-6 mb-12 mb-lg-0'>
                  <div className='mw-md mx-auto'>
                    <h3 className='text-white fs-1 mb-6'>
                      I'm passionate about design and technology.
                    </h3>
                    <p className='mw-xs text-secondary-light mb-10'>
                      I specialize in creating high-quality, professional
                      websites, designed to elevate small businesses and ensure
                      a strong online presence.
                    </p>
                    <Link className='btn btn-outline-light' to='/contact'>
                      <span className='me-2'>Get In Touch</span>
                      <svg
                        className='rotate-45'
                        width={10}
                        height={11}
                        viewBox='0 0 10 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M9 1.5L1 9.5'
                          stroke='currentColor'
                          strokeWidth='1.3'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9 8.83571V1.5H1.66429'
                          stroke='currentColor'
                          strokeWidth='1.3'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className='col-12 col-lg-6'>
                  <img
                    className='img-fluid d-block rounded-5'
                    src='https://ik.imagekit.io/s3i7pk3bg/Project_Images/linus-mimietz-UzbvepPfb14-unsplash.jpg?updatedAt=1690046216931'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-12 py-md-24 bg-light-light'>
          <div className='container'>
            <h2 className='fw-medium mb-20 text-center image-text'>More About Me</h2>
            <div className='mw-md mx-auto mw-lg-none'>
              <div className='row'>
                <div className='col-12 col-lg-4 mb-6 mb-lg-0'>
                  <Link
                    className='position-relative btn h-100 w-100 btn-outline-dark text-start px-9 py-14 border rounded-5'
                    to='/skillsandresume'
                  >
                    <span className='position-absolute top-0 end-0 mt-16 me-8 text-light-light'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M22 2L2 22'
                          stroke='currentColor'
                          strokeWidth='3.25'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M22 20.3393V2H3.66071'
                          stroke='currentColor'
                          strokeWidth='3.25'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </span>
                    <div className='mw-xs mw-lg-none pe-10'>
                      <span className='d-block fs-3 mb-6'>
                        My Professional Background
                      </span>
                      <p className='mb-0'>
                        I'm a Full-Stack Web Developer and former Educator from Houston, TX. The educational challenges and disparities in the community I worked in, combined with my exposure to ed tech and data, drove me to learn to code and focus on creating more inclusive digital experiences. Click here to view my career trajectory through my resume.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className='col-12 col-lg-4 mb-6 mb-lg-0'>
                  <div
                    className='position-relative btn h-100 w-100 btn-outline-dark text-start px-9 py-14 border rounded-5'
                  >
                    <div className='mw-xs mw-lg-none pe-10'>
                      <span className='d-block fs-3 mb-6'>
                        My Everyday Values
                      </span>
                      <p className='mb-0'>
                        Regardless of the hat I wear — Educator or Developer — my foundational values persist. These encompass respect towards others, an earnest social conscience, connection through empathy, and a commitment of fostering inclusive environments. Each day, these guiding principles shape my decisions, interactions, and contributions, aiming for a meaningful impact in all that I do.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div
                    className='position-relative btn h-100 w-100 btn-outline-dark text-start px-9 py-14 border rounded-5'
                  >
                    <div className='mw-xs mw-lg-none pe-10'>
                      <span className='d-block fs-3 mb-6'>
                        My Work Philosophy
                      </span>
                      <p className='mb-0'>
                      In every challenge lies an opportunity, and my philosophy revolves around seeking out these learning moments. To me, excellence is a continuous pursuit, framed by both triumphs and lessons learned. With an eye for detail and a heart for inclusivity, I approach every task as an avenue to improve and innovate. Every encounter, every project becomes an opportunity for me to cultivate shared understanding and promote collaborative success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

