import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

export default function Contact() {
  const [state, handleSubmit] = useForm("mzblzbkr");

  return (
    <React.Fragment>
      <section className='pt-24 pb-10 pb-lg-32 bg-light-light overflow-hidden'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-6 mb-16 mb-lg-0'>
              <div className='col-12 mb-8 mb-sm-0 '>
                <h1 className='d-flex justify-content-center text-center'>Get In Touch</h1>
                <p className='d-block text-secondary-light fw-semibold'>
                  Feel free to connect with me on LinkedIn or by filling out the form below.
                  <span className='ps-1 mb-12 text-muted fw-semibold'>
                    I'll get back to you within 48 hours.
                  </span>
                </p>
                <div className='d-flex justify-content-end'>
                  <a
                    className='pb-2 fw-semibold border-2 border-dark border-bottom'
                    href='https://www.linkedin.com/in/fidel-deaquino/'
                  >
                    <span className='me-3'>Connect on LinkedIn</span>
                    <svg
                      className='rotate-45'
                      width={11}
                      height={11}
                      viewBox='0 0 11 11'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M9.5 1.5L1.5 9.5'
                        stroke='black'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M9.5 8.83571V1.5H2.16429'
                        stroke='black'
                        strokeWidth='1.3'
                        strokeMiterlimit={10}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </a>
                </div>

                {state.succeeded ? (
                  <div className="success-message">
                    <h3 className='text-center'>Thanks for your submission!</h3>
                    <p className="cs-text text-center">
                      I will get back to you as soon as possible, no later than 2 business days.
                    </p>
                  </div>
                ) : (
                  // Your form content here...
                  <form onSubmit={handleSubmit}>
                    <input
                      className='form-control mt-10 mb-6'
                      type='text'
                      placeholder='Full name'
                      name="full_name"
                    />
                    <input
                      className='form-control mb-6'
                      type='email'
                      placeholder='E-mail'
                      name="email"
                      id="email"
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <textarea
                      className='form-control mb-8 rounded-4'
                      placeholder='Message'
                      style={{ resize: 'none', height: 250 }}
                      rows={3}
                      name="message"
                      id="message"
                    />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                    <div className='mb-10 d-flex flex-column align-items-center'>
                      <span className='d-block mb-6 fw-semibold'>
                        What can we help you with?
                      </span>
                      <div className='d-sm-flex justify-content-center flex-wrap'>
                        {/* These checkboxes have the same name to group them together */}
                        <div className='mb-4 mb-sm-0 me-sm-8'>
                          <div className='form-check mb-4'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name="help_topic"
                              value="Website development"
                            />
                            <label className='form-check-label ms-2'>
                              Website development
                            </label>
                          </div>
                          {/* ... other checkboxes ... */}
                        </div>
                        <div className='mb-4 mb-sm-0 me-sm-8'>
                          <div className='form-check mb-4'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name="help_topic"
                              value="Website design"
                            />
                            <label className='form-check-label ms-2'>
                              Website design
                            </label>
                          </div>
                          {/* ... other checkboxes ... */}
                        </div>
                        <div className='mb-4 mb-sm-0 me-sm-8'>
                          <div className='form-check mb-4'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name="help_topic"
                              value="Blog post feedback"
                            />
                            <label className='form-check-label ms-2'>
                              Blog post feedback
                            </label>
                          </div>
                          {/* ... other checkboxes ... */}
                        </div>
                        <div>
                          {/* Content creation checkboxes */}
                          <div className='form-check mb-4'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name="help_topic"
                              value="Something else"
                            />
                            <label className='form-check-label ms-2'>
                              Something else
                            </label>
                          </div>
                          {/* ... other checkboxes ... */}
                        </div>
                      </div>
                    </div>
                    <button className='btn w-100 btn-lg btn-dark' type='submit' disabled={state.submitting}>
                      Send message
                    </button>
                  </form>
                )}
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <img
                className='d-block mw-lg mx-auto mw-lg-none img-fluid h-100 w-100 rounded-5'
                style={{ objectFit: 'cover' }}
                src='https://ik.imagekit.io/s3i7pk3bg/Project_Images/houston-map.png?updatedAt=1689966215587'
                alt=''
              />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
