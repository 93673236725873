import React, { useState, useEffect } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import SinglePost from './Single-post';

function createPreview(description) {
  let words = description.split(" ");
  if (words.length > 25) {
    return words.slice(0, 25).join(" ") + "...";
  } else {
    return description;
  }
}

export default function Blog({ blogData }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Blogs to show
  const blogsToShow = blogData.slice(currentIndex, currentIndex + 10);

  const handlePreviousClick = () => {
    const container = document.querySelector(".blog-scroll-container");
    if (container) {
      // Check if we've reached the beginning of the scrollable area
      const isAtStart = container.scrollLeft <= 0;

      if (isAtStart) {
        // Set scroll position to the end
        container.scrollLeft = container.scrollWidth - container.offsetWidth;
      } else {
        // Continue scrolling to the left
        container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
      }
    }
  };


  const handleNextClick = () => {
    const container = document.querySelector(".blog-scroll-container");
    if (container) {
      // Check if we've reached near the end of the scrollable area
      const isNearEnd = (container.scrollLeft + container.offsetWidth) >= container.scrollWidth - 100;
      if (isNearEnd) {
        // Reset scroll position to the beginning
        container.scrollLeft = 0;
      } else {
        // Continue scrolling to the right
        container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
      }
    }
  };


  const [showSvg, setShowSvg] = useState(false);

  useEffect(() => {
    const timerToShow = setTimeout(() => {
      setShowSvg(true);
      const timerToHide = setTimeout(() => {
        setShowSvg(false);
      }, 3000);
      return () => clearTimeout(timerToHide);
    }, 1000);
    return () => clearTimeout(timerToShow);
  }, []);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1023);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1023);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <React.Fragment>
      <Routes>
        <Route index element={
          <section className='pt-24 pb-10 bg-light-light overflow-hidden'>
            <div id="this-one" className='container'>
              <div id='mb-for-mobile' className='row align-items-center mb-20'>
                <div className='col-12 mb-8 mb-sm-0'>
                  <h1 class="d-flex justify-content-center pb-2">Latest Blog Posts</h1>
                </div>
                <div className='col-12'>
                  <div className='d-flex align-items-center justify-content-end py-3 px-11 pe-4'>
                    <button
                      className='btn me-8 d-inline-flex align-items-center justify-content-center p-0 btn-outline-dark rounded-pill'
                      style={{ width: 74, height: 74 }}
                      onClick={handlePreviousClick}
                    >
                      <svg
                        width={27}
                        height={27}
                        viewBox='0 0 27 27'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.7051 7.12817L4.15732 13.6759L10.7051 20.2237'
                          stroke='currentColor'
                          strokeWidth='1.61806'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M22.4941 13.6759H4.33949'
                          stroke='currentColor'
                          strokeWidth='1.61806'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </button>
                    <button
                      className='btn d-inline-flex align-items-center justify-content-center p-0 btn-outline-dark rounded-pill'
                      style={{ width: 74, height: 74 }}
                      onClick={handleNextClick}
                    >
                      <svg
                        width={27}
                        height={27}
                        viewBox='0 0 27 27'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M16.2949 7.12817L22.8427 13.6759L16.2949 20.2237'
                          stroke='currentColor'
                          strokeWidth='1.61806'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M4.50586 13.6759H22.6605'
                          stroke='currentColor'
                          strokeWidth='1.61806'
                          strokeMiterlimit={10}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              {/* DISPLAY THE SVG LAYERED OVER THIS DIV */}
              <div className='d-flex mb-20'>
                <div
                  className={`fade-in-out ${showSvg && isMobileView ? '' : 'd-none'}`}
                  style={{ position: "absolute", top: 200, left: 0, right: 0, bottom: 0, zIndex: 1000, display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  <img src="https://cdn-icons-png.flaticon.com/512/624/624846.png" width={200} height={200} alt="Overlay SVG" />
                </div>

                <div id='other-one' className="blog-scroll-container">

                  {blogsToShow.map((blog, index) => {
                    return (
                      <div key={blog.id} className='blog-post flex-md-shrink-0 col-12 col-sm-7 col-md-5 ms-5 me-5'>
                        <Link className='d-block' to={`/blog/${blog.id}`}>
                          <img
                            className='img-fluid d-block w-100 mb-6 rounded-5'
                            style={{ objectFit: 'cover', height: 290 }}
                            src={blog.imgSrc} alt={blog.altText}
                          />
                          <div className=''>
                            <div className='mb-3'>
                              <span className='badge me-3 border border-dark'>
                                {blog.category}
                              </span>
                              {
                                blog.category2 && (
                                  <>
                                    <span className='badge me-3 border border-dark'>
                                      {blog.category2}
                                    </span>
                                  </>
                                )
                              }
                              {
                                blog.category3 && (
                                  <>
                                    <span className='badge me-3 border border-dark'>
                                      {blog.category3}
                                    </span>
                                  </>
                                )
                              }
                              <span className='d-inline-block fs-9 text-secondary-light'>
                                {blog.readTime} min read
                              </span>
                            </div>
                            <h4 className='fs-3 fw-medium mb-6'>
                              {blog.title}
                            </h4>
                            <p>
                              {createPreview(blog.description)}
                            </p>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='text-center'>
                <Link
                  className='d-inline-block pb-2 fw-semibold border-0 border-2 border-dark border-bottom'
                  to='/contact'
                >
                  <span className='me-3'>Give Feedback</span>
                  <svg
                    className='rotate-45'
                    width={11}
                    height={11}
                    viewBox='0 0 11 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.5 1.5L1.5 9.5'
                      stroke='black'
                      strokeWidth='1.3'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9.5 8.83571V1.5H2.16429'
                      stroke='black'
                      strokeWidth='1.3'
                      strokeMiterlimit={10}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </section>
        } />
        <Route path=":id" element={<SinglePost blogData={blogData} />} />
      </Routes>
    </React.Fragment>
  );
}

